import mnml from '@dryan-llc/mnml.js';

const styleSheet = (() => {
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(''));
  document.head.appendChild(style);
  return style.sheet;
})();

mnml.listen('click', '.primary-nav-toggle', (ev, button) => {
  ev.preventDefault();
  document.documentElement.classList.toggle('primary-nav-open');
  button.blur();
});

mnml.listen('click', '.primary-nav-item-toggle', (ev, button) => {
  ev.preventDefault();
  mnml
    .findParent(button, '.primary-nav-item')
    .classList.toggle('primary-nav-item-open');
  button.blur();
});

mnml.listen(
  'click',
  ':is(.pointer-coarse, .hover-off) .primary-nav-item-with-children > .primary-nav-link',
  (ev, link) => {
    const item = mnml.findParent(link, '.primary-nav-item');
    if (!item.classList.contains('primary-nav-item-open')) {
      ev.preventDefault();
      item.classList.add('primary-nav-item-open');
    }
  }
);

mnml.listen('click', 'body', (ev, primaryNav) => {
  if (!primaryNav) {
    [...document.querySelectorAll('.primary-nav-item-open')].map((item) =>
      item.classList.remove('primary-nav-item-open')
    );
    document.documentElement.classList.remove('primary-nav-open');
  }
});

const siteHeader = document.querySelector('.site-header');
const clonedHeader = siteHeader.cloneNode(true);
const clonedNav = clonedHeader.querySelector('.primary-nav');
clonedHeader.dataset.display = window.getComputedStyle(clonedHeader)['display'];
clonedHeader.style.display = 'none';
clonedHeader.setAttribute('aria-hidden', 'true');
document.body.appendChild(clonedHeader);

const updateNavSizes = () => {
  if (document.documentElement.getBoundingClientRect().width >= 1024) {
    requestAnimationFrame(() => {
      clonedHeader.style.display = 'grid';
      [...clonedNav.querySelectorAll('.primary-nav-item-with-children')].map(
        (item) => item.classList.add('primary-nav-item-open')
      );
      while (styleSheet.cssRules.length) {
        styleSheet.deleteRule(0);
      }
      styleSheet.insertRule(
        `@media (min-width: 1024px){.js .primary-nav-item-children { height: auto; }}`
      );
      let maxChildrenHeight = 0;
      let navHeight = Math.ceil(
        clonedNav.getBoundingClientRect().height +
          parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
      styleSheet.deleteRule(0);
      styleSheet.insertRule(
        `@media (min-width: 1024px){.js .primary-nav:focus-within, .js.hover-on .primary-nav:hover, .js.hover-off .primary-nav:focus-within, .no-js .primary-nav { height: ${navHeight}px; }}`
      );
      [...clonedNav.querySelectorAll('.primary-nav-item-with-children')].map(
        (item) => item.classList.remove('primary-nav-item-open')
      );
      styleSheet.insertRule(
        `@media (min-width: 1024px){.js .site-header-wrapper { padding-top: ${
          clonedHeader.getBoundingClientRect().height
        }px; }}`
      );
      clonedHeader.style.display = 'none';
    });
  }
};

const navResizeObserver = new ResizeObserver(updateNavSizes);
navResizeObserver.observe(document.documentElement);

const htmlClassesObserver = new MutationObserver(updateNavSizes);
htmlClassesObserver.observe(document.documentElement, {
  attributeFilter: ['class'],
});

try {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (tz) {
    const parts = [`tz=${tz}`, 'path=/', 'SameSite=Strict'];
    if (location.protocol === 'https:') {
      parts.push('Secure');
    }
    document.cookie = parts.join(';');
  }
} catch (err) {}

(() => {
  if (window.customElements.get('ftx-video-embed')) return;

  window.customElements.define(
    'ftx-video-embed',
    class extends HTMLElement {
      connectedCallback() {
        // <iframe width="200" height="113" src="https://www.youtube.com/embed/iuk77TjvfmE?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="Marvel Studios’ The Marvels | Teaser Trailer"></iframe>
        const link = this.querySelector('a');
        const icon = mnml.html`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>`;
        if (!link.querySelector('svg')) {
          link.append(icon);
        }
        link.addEventListener('click', (ev) => {
          ev.preventDefault();
          const iframe = mnml.html`<iframe width="${this.getAttribute(
            'width'
          )}" height="${this.getAttribute('height')}" src="${this.getAttribute(
            'url'
          )}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="${
            this.dataset.title
          }" style="aspect-ratio: ${this.getAttribute(
            'width'
          )} / ${this.getAttribute('height')}"></iframe>`;
          link.replaceWith(iframe);
        });
      }
    }
  );
})();

document.body?.addEventListener('click', (event) => {
  if (event.target.closest('.footer-contact-email a')) {
    window.gtag?.('event', 'footer-contact-click');
  }
});
